import React, { Component } from 'react';
import '../styles/social.css';

class Social extends Component {
    render() {
        return (
            <div className="social">
                <a href="https://github.com/vinaykuresi" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
                <a href="https://www.linkedin.com/in/vinay-kumar-kuresi/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
            </div>
            )
        }
    }
    
export default Social
    