import React, { Component } from 'react';
import Social from '../components/Social';
import '../styles/commonHome.css';

class Contact extends Component {
    render() {
        return (
            <div className="condiv">
            <h1 className="subtopic">Contact Me</h1>
            <h3 style={{fontSize:"22px"}}>Email  :   Hello@VinayKumarKuresi.com</h3>
            <h3 style={{fontSize:"22px"}}>Linkedin   :   @Vinay kumar Kuresi</h3>
            <Social />
            </div>
            )
        }
    }
    
    export default Contact
    
