import React, { Component } from 'react';
import certificates from '../static/storage/certifications.json';
import '../styles/certificationShow.css';
import cert1 from '../static/certiifications/algorithms.PNG'
import cert2 from '../static/certiifications/botGoogle.PNG'
import cert3 from '../static/certiifications/botPython.PNG'
import cert4 from '../static/certiifications/dataScience.PNG'
import cert5 from '../static/certiifications/dataStructures.PNG'
import cert6 from '../static/certiifications/deepLearning.PNG'
import cert7 from '../static/certiifications/infosys1.PNG'
import cert8 from '../static/certiifications/infosys2.PNG'
import cert9 from '../static/certiifications/java.PNG'
import cert10 from '../static/certiifications/mathematics.PNG'
import cert11 from '../static/certiifications/python.PNG'
import cert12 from '../static/certiifications/FrontEndDeveloper.png'


class Certification extends Component {

    render() {
        var certifications = [
            [cert7, "Infosys","cert7"],
            [cert8, "Infosys","cert8"],
            [cert12, "HTML, CSS, Javascript, Responsive Design, Client side Web Security","cert12"],
            [cert1, "University of California San Diego","cert1"],
            [cert5, "University of California San Diego","cert5"],
            [cert4, "DataCamp","cert4"],
            [cert2, "Google","cert2"],
            [cert6, "DeepLearning.AI","cert6"],
            [cert10, "Imperial College London","cert10"],
            [cert11, "University of Michigan","cert11"],
            [cert3, "DataCamp","cert3"]
        ]
        return (
            <div className="condiv">
                <h1 className="subtopic">Certifications</h1>
                <div className="slider-wrap">
                    <div className="slider" id="slider">
                        <div className="holder">
                            {certifications.map((certificate) => {
                                return (<div className="slide" key={certificate[2]} id={certificate[2]}><span className="temp">{certificate[1]}</span></div>)
                            })}
                        </div>
                    </div>
                </div>
                {certificates.map(certificate => {
                    return <CertificateDisplay certificate={certificate} />
                })}
            </div>
        )
    }
}

const CertificateDisplay = (props) => {
    return (
        <div key={props.certificate[0]} className="widecard">
            <div className="certify">
                <h3>{props.certificate[0]}</h3>
                <h5 className="secondtext">Certifier : {props.certificate[1]}</h5>
                <a href={props.certificate[2]} target="_blank" rel="noopener noreferrer" className="secondtext" data-toggle="tooltip" data-placement="top" title="Click here to see Credentials Certificate">See Credentials</a>
            </div>
        </div>
    )
}

export default Certification;
