import React, { Component } from 'react';
import Widecard from '../components/Widecard';
import '../styles/commonHome.css';

class Education extends Component {
    render() {
        return (
            <div className="condiv">
            <h1 className="subtopic">My Education</h1>
                <Widecard title="Masters in Computer Applications" where="Jawaharlal Nehru Technological Unversity" from="July 2015" to="May 2018" percentage="Percentage : 86%"/>
                <Widecard title="Bachelor in Computer Applications" where="Dr C.V Raman University" from="2012" to="2015" percentage="Percentage : 75%"/>
                <Widecard title="Intermediate(M.P.C)" where="Andhra pradesh board of secondary and Intermediate education" from="2010" to="2012" percentage="Percentage : 87%"/>
                <Widecard title="SSC" where="Andhra pradesh board of secondary education" from="2009" to="2010" percentage="Percentage : 90%"/>
            </div>
            )
        }
    }
    
export default Education
    