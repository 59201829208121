import React, { Component } from 'react';
import project from '../static/storage/projects.json';
import '../styles/commonHome.css';

class SampleProjects extends Component {
    render() {
        return (
            <div className="condiv">
                <h1 className="subtopic">Sample Work</h1>
                {project.map((value) => {
                    return (
                        <div className="widecard" key={value[0]}>
                            <div className="compdet">
                                <h3>{value[0]}</h3>
                                <h5 className="secondtext">{value[1]}</h5><br/>
                                <a href={value[2]} target="_blank" rel="noopener noreferrer" className="secondtext" data-toggle="tooltip" data-placement="top" title="Click here to Visit Code Repository">Code Repository</a><br/>
                                <br/>
                                <a href={value[3]} target="_blank" rel="noopener noreferrer" className="secondtextWebSite" data-toggle="tooltip" data-placement="top" title="Click here to Visit the WebSite">Live Site</a>
                                <br/>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default SampleProjects;