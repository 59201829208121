import React, { Component } from 'react';
import '../styles/commonHome.css';

class About extends Component {
    render() {
        return (
            <div className="condiv">
            <h1 className="subtopic">About Me</h1>
            <h4 style={{fontStyle:"oblique", fontSize:"130%"}}>Hey there,</h4>
            <h1>I'm Vinay Kumar Kuresi</h1>
            <h3>MERN & Django Full Stack Web Developer</h3>
            <br></br>
            {/* <p>Looking for Full stack engineering role to utilize my background and experience as full stack engineer.</p>
            <br/>
                <h3 style={{ textDecoration: 'underline' }}>Experinece</h3>
                    <div className="widecard">
                        <div>
                            <h4>&nbsp;&nbsp;&nbsp;INFOSYS</h4>
                            <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From July 2018 - Present | 2 years 8 months</h5>
                            <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MERN & Django Full Stack Web Developer</h5>
                        </div>
                    </div> */}
            </div>
            )
        }
    }
    
export default About
