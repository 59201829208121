import React, { Component } from 'react';
import Navitem from './Navitem';
import '../styles/navbar.css';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'NavItemActive': ''
        }
    }

    componentDidMount(){
        this.activeitem("Home")
    }

    activeitem = (x) => {
        document.getElementById("mySidenav").style.width = "0";
        if (this.state.NavItemActive.length > 0) {
            document.getElementById(this.state.NavItemActive).classList.remove('active');
        }
        this.setState({ 'NavItemActive': x }, () => {
            document.getElementById(this.state.NavItemActive).classList.add('active');
        });
    };

    openNav = (e) => {
        document.getElementById("mySidenav").style.width = "200px";
        document.getElementById("mySidenav").style.height = "370px";
    }

    closeNav = (e) => {
        document.getElementById("mySidenav").style.width = "0";
    }

    render() {
        return (
            <div className="sideNav">
                <nav className="deskNav">
                    <ul>
                        <Navitem item="Home" tolink="/" activec={this.activeitem}></Navitem>
                        <Navitem item="About" tolink="/about" activec={this.activeitem}></Navitem>
                        <Navitem item="Education" tolink="/education" activec={this.activeitem}></Navitem>
                        <Navitem item="Skills" tolink="/skills" activec={this.activeitem}></Navitem>
                        <Navitem item="Certifications" tolink="/certifications" activec={this.activeitem}></Navitem>
                        <Navitem item="Sample Projects" tolink="/sample_projects" activec={this.activeitem}></Navitem>
                        <Navitem item="Contact" tolink="/contact" activec={this.activeitem}></Navitem>
                    </ul>
                </nav>
                <nav className="mobileNav">
                    <div id="mySidenav" className="sidenav">
                        <a className="closebtn" onClick={this.closeNav}>&times;</a>
                        <Navitem item="Home" tolink="/" activec={this.activeitem}></Navitem>
                        <Navitem item="About" tolink="/about" activec={this.activeitem}></Navitem>
                        <Navitem item="Education" tolink="/education" activec={this.activeitem}></Navitem>
                        <Navitem item="Skills" tolink="/skills" activec={this.activeitem}></Navitem>
                        <Navitem item="Certifications" tolink="/certifications" activec={this.activeitem}></Navitem>
                        <Navitem item="Sample Projects" tolink="/sample_projects" activec={this.activeitem}></Navitem>
                        <Navitem item="Contact" tolink="/contact" activec={this.activeitem}></Navitem>
                    </div>
                    <span id="aboutMe" onClick={this.openNav}>&#9776; About Me</span>
                </nav>
            </div>
        )
    }
}

export default Navbar
