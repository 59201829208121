import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";

class Navitem extends Component {
    constructor(props){
        super(props);
    }

    render() {
                return (
                    <li key={this.props.item} id={this.props.item}>
                        <Link className="NavBar" to={this.props.tolink} onClick={this.props.activec.bind(this,this.props.item)}>{this.props.item}</Link>
                    </li>
                    )
            }
        }
        
export default Navitem
        