import React, { Component } from 'react';
import ReactTypingEffect from 'react-typing-effect';
import Social from '../components/Social';
import '../styles/home.css';
import image1 from '../static/img/vinay.jpg'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flag: false,
        };
    }
    render() {
        return (
            <div className="condiv home">
                <div className="hidden-lg hidden-md container">
                    <img className="smallPhoto" src={image1} />
                </div>
                <ReactTypingEffect className="typingeffect" text={['I am Vinay Kumar Kuresi', 'I am an Full Stack Developer']} speed={100} eraseDelay={700} />
                <Social />
            </div>
        )
    }
}

export default Home;
